div.list-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #000000;
  border: 1px solid #484747;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: none;
  height: 200px;
  width: 200px;
}

div.BaseInputContainer {
  width: 100%;
  border: 1px solid #ced4da;
  color: #495057;
  border-radius: 6px;
  vertical-align: middle;
}

div.BaseInputContainer:hover {
  border: 1px solid #6366F1;
}

div.BaseInputContainer:focus-within {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #C7D2FE;
  border-color: #6366F1;
}

input.BaseInput {
  padding: 10px;
  height: 100%;
  width: 90%;
  border: none;
  border-radius: 6px;
}

input.BaseInput:focus {
  outline: none;
}

.BaseListContainer {
  transition-duration: 1s;
}

.ListValuesContainer {
  overflow: hidden;
  max-height: 0px;
  transition-property: max-height;
  transition-duration: 1.5s;
}