/**** PrimeGrid Styles for CadActive ****/

/* Light theme styles */
:root {
  --surface-ground: #ffffff;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dee2e6;
  --text-color: #495057;
  --text-color-secondary: #6c757d;

  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;

  --primary-color: #63b3ed;
  --primary-color-text: #ffffff;
}

/* Dark theme styles */
[data-theme="dark"] {
  --surface-ground: #1a1a1a;
  --surface-section: #2d2d2d;
  --surface-card: #2d2d2d;
  --surface-overlay: #2d2d2d;
  --surface-border: #404040;
  --text-color: #e6e6e6;
  --text-color-secondary: #cccccc;

  --surface-a: #1a1a1a;
  --surface-b: #2d2d2d;
  --surface-c: #404040;
  --surface-d: #4d4d4d;
  --surface-e: #666666;
  --surface-f: #808080;

  --primary-color: #63b3ed;
  --primary-color-text: #ffffff;
}

/* Background Colors */
.bg-edited {
  background: rgba(77, 171, 247, .25) !important;
}

.bg-deleted {
  background: rgba(173, 181, 189, .3) !important;
}

.bg-created {
  background: rgba(77, 171, 247, .25) !important;
}

.bg-invalid {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 135, 135, .25) !important;
}

.p-chips-multiple-container {
  border: none;
}

.text-cell-body {
  width: 100%;
  overflow: ellipsis;
  white-space: nowrap;
}

.flex-grid-wrapper {
  border: '1px solid #DEE2E6';
  border-radius: '5px';
  height: '86%';
}

td.hide-row-reorder-button>svg {
  width: 0px;
  height: 0px;
}

/* Checkbox Default Styles */
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: rgb(34, 139, 230);
  background-color: rgb(34, 139, 230);
}

/* Input styles */
.p-inputtext {
  width: 100%;
  color: var(--text-color);
  background-color: var(--surface-ground);
  border-color: var(--surface-border);
}

.p-inputtext:enabled:hover {
  border-color: var(--surface-border);
}

.p-inputtext:enabled:focus {
  border-color: var(--surface-border);
  box-shadow: 0 0 0 1px var(--surface-border);
}

/* Input Switch Styles */
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: rgb(77, 171, 247);
}

/* Dropdown Default Styles */
.p-dropdown-panel .p-dropdown-header {
  background-color: var(--surface-a);
  padding: 0;
}

.p-dropdown {
  background: var(--surface-ground);
  border: 1px solid var(--surface-border);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
}

.p-dropdown,
.p-dropdown-items-wrapper {
  background-color: var(--surface-ground);
}

.p-dropdown .p-dropdown-label.p-inputtext {}
.p-datatable-table .p-dropdown .p-dropdown-label.p-inputtext {
  padding: 0;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: rgb(77, 171, 247);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: rgb(77, 171, 247);
  background-color: var(--surface-section);
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1.75rem; /* to handle the search icon */
}

/* MultiSelect Default Styles */
.p-multiselect {
  background: var(--surface-ground);
}

.p-multiselect .p-multiselect-label {
  color: #495057;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: #6B7280;
}

.p-multiselect:not(.p-disabled).p-focus {
  border-color: #6B7280;
  box-shadow: 0 0 0 2px rgba(107, 114, 128, 0.2);
}
.p-multiselect-panel {
  background: var(--surface-e);
  border: 1px solid var(--surface-border);
}

.p-multiselect-panel .p-multiselect-header {
  background-color: var(--surface-section);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  color: var(--text-color);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--text-color);
  background-color: var(--surface-b);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--text-color);
  background-color: var(--surface-b);
}

.p-multiselect-trigger {
  color: #6B7280;
}

.p-multiselect-clear-icon {
  color: #6B7280;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  color: var(--text-color);
}
[data-theme="dark"] {
  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    background: var(--surface-section);
  }
}


/* Textarea styles */
.p-inputtextarea {
  background-color: var(--surface-ground);
  color: var(--text-color);
  border-color: var(--surface-border);
}

.p-inputtextarea:enabled:hover {
  border-color: var(--surface-border);
}

.p-inputtextarea:enabled:focus {
  border-color: var(--surface-border);
  box-shadow: 0 0 0 1px var(--surface-border);
}

/* Table styles */
.p-datatable {
  background-color: var(--surface-card);
  color: var(--text-color);
  transition: background-color 0.2s ease, color 0.2s ease;
  border-radius: 5px;
}

.p-datatable-wrapper {
  border-radius: 5px;
}

/* Table Header styles */
.p-datatable .p-datatable-thead {
  background-color: var(--surface-b);
}

.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-tfoot {
    background-color: var(--surface-b);
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0px 1rem;
  height: 54px;
  color: var(--text-color);
  border-color: var(--surface-border);
  background-color: var(--surface-b);
  transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
}

[data-theme="dark"] {
  .p-datatable .p-datatable-thead {
    background-color: var(--surface-a);
  }

  .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
  .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot,
  .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-thead,
  .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-tfoot {
      background-color: var(--surface-a);
  }

  .p-datatable .p-datatable-thead > tr > th {
    background-color: var(--surface-a);
  }
}

.p-datatable .p-sortable-column.p-highlight {
    background: #EEF2FF;
    color: var(--primary-color);
}

/* Table Body styles */
.p-datatable.p-datatable-striped .p-datatable-tbody > tr {
    background-color: var(--surface-section);
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
    background-color: var(--surface-section);
}

.p-datatable .p-datatable-tbody > tr {
  background-color: var(--surface-card);
  color: var(--text-color);
  border-color: var(--surface-border);
  transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0px 1rem;
  height: 54px;
  border-color: var(--surface-border);
  transition: border-color 0.2s ease;
}

.p-datatable .p-datatable-tbody > tr:hover {
  background-color: var(--surface-section);
}

/* Dialog styles */
.p-dialog {
  background-color: var(--surface-card);
  color: var(--text-color);
}

.p-dialog .p-dialog-header {
  background-color: var(--surface-section);
  color: var(--text-color);
  border-color: var(--surface-border);
}

.p-dialog .p-dialog-content {
  background-color: var(--surface-card);
  color: var(--text-color);
}

.p-dialog .p-dialog-footer {
  background-color: var(--surface-section);
  border-color: var(--surface-border);
}

/* Migrated from Framework */
.gridBox {
  border: 1px solid #DEE2E6;
  border-radius: 5px;
  height: 87%;
  display: grid;
  gap: 20px;
  grid-template-columns: 267px auto auto auto;
  grid-template-rows: 100%;
  min-width: 436px;
}

.CreoTable {
  grid-column-start: 1;
  grid-column-end: 2;
  border: 1px solid #DEE2E6;
  border-radius: 5px;
  margin: 20px 0px 20px 20px;
  direction: rtl;
}

.tableEditTable {
  border: 1px solid #DEE2E6;
  border-radius: 5px;
  margin: 20px 20px 20px 0px;
  grid-column-start: 2;
  grid-column-end: 5;
  width: calc( 100% - 40px );
  min-width: 200px;
}
