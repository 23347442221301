.filter-input-container {
  /* text-align: right; */
  width: 100%;
  padding: 0px 0px 10px 0px;
}

.filter-text-input {
  border: 1px solid lightgrey;
  border-radius: 3px;
  height: calc(1rem + 10px);
}

.filter-text-input:focus {
  outline: none;
  border: 2px solid rgba(75, 100, 255, .6);
  border-radius: 4px;
}