.p-colorpicker-preview {
  width: 50%;
  height: 20px !important;
}

.p-colorpicker {
  height: 20px !important;
}

.entity-tools-container {
  width: 100%;
  display: inline-block;
}